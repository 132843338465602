<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-list-item three-line @click="goToGroupSinglePage(group.id)">
        <v-list-item-avatar>
          <v-img v-if="group.logo" :alt="`${group.name} logo`" :src="group.logo"/>
          <v-icon v-else x-large>mdi-account-group</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-html="`<b>${group.name}</b>`"/>
          <v-list-item-subtitle
            v-text="isAdmin ? $vuetify.lang.t('$vuetify.pages.groups.admin') : $vuetify.lang.t('$vuetify.pages.groups.member')"/>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-subtitle
            v-html="`<b>${$vuetify.lang.t('$vuetify.pages.groups.upcomingInspections')}</b> ${group.upcoming_inspections_count}`"/>
          <v-list-item-subtitle
            v-html="`<b>${$vuetify.lang.t('$vuetify.pages.groups.inspectionMemberCount')}</b> ${group.staff_count}`"/>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="12">
      <slot name="divider"/>
    </v-col>
  </v-row>
</template>

<script>
import Group from '@/modules/groups/models/group'

export default {
  name: 'KurccGroupInspectionListItem',
  props: {
    group: {
      type: Group,
      required: true
    }
  },
  computed: {
    isAdmin () {
      return this.$auth.user().isAdminInGroup(this.group.id)
    }
  },
  methods: {
    goToGroupSinglePage (id) {
      this.$router.push({
        name: 'group',
        params: {
          group_id: id,
          group: this.group
        },
        query: { tab: 'inspections' }
      })
    }
  }
}
</script>
